@import '../../styles/AppDesign.scss';

.VisitorJumbotron {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;

  &-title {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 0;
    padding-top: 20px;

    strong {
      color: $MainCyan;
    }

    &.mod-smallerTitle {
      font-size: 29px;
    }
  }

  &-description {
    font-size: 18px;
    color: #9aa5b1;
    margin-bottom: 20px;
  }
}
