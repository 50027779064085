@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.ActionCompleteLayout {
  &-confettiBall {
    padding: 26px 0px;
    font-size: 125px;
  }

  &-buttonsContainer {
    max-width: 200px;
  }
}

@include desktop {
  .ActionCompleteLayout {
    .VisitorLayout {
      &-container {
        height: 100%;
      }

      &-form-buttons {
        margin-bottom: auto;
      }
    }
  }
}
