@import '../../styles/AppDesign.scss';

.cta-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: $white;
  height: 55px;
  font-weight: 600;
  font-size: 1rem;
  border-radius: 27.5px;
  border: 1px solid $white;
  background-origin: border-box;
  background-color: $MainCyan;
  padding: 0;
  margin: 0;
  cursor: pointer;
  // max-width: 200px;

  &:active {
    background-color: $MainLightCyan;
  }

  img {
    max-width: 25px;

    &.mod-left {
      margin-right: 0.7rem;
    }

    &.mod-right {
      margin-left: 0.7rem;
    }
  }

  span {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.mod-fill {
    color: $MainCyan;
    background-color: $white;
  }

  &.mod-invert {
    border: 1px solid $MainCyan;
    background-color: transparent;
    color: $MainCyan;

    &:active {
      background-color: $bgGray;
    }
  }

  &.mod-disabled {
    opacity: 0.5;
    cursor: progress;
  }

  &.mod-no-border {
    border: none;
  }
}
