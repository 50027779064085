@import '../../styles/AppDesign.scss';

.AlertModal {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  &-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
    text-align: center;
    margin: 2rem 0;
    flex: 1;

    &-description {
      margin: 0;
      color: $TextColor;
      text-align: center;
      font-size: 18px;
      font-weight: 600;
    }

    &-errorMessage {
      color: $ErrorRed;
      font-size: 14px;
      margin: 0px;
    }
  }

  &-buttonsRow {
    flex: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: auto;

    .cta-button {
      flex: 1;
      min-height: 45px;

      &:first-child {
        margin-bottom: 0.7rem;
      }
    }
  }
}
