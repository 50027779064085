.CreditCardPaymentForm {
  &-credit-card-logos {
    &-item {
      height: 50px;
      box-sizing: border-box;
      object-fit: contain;
      padding: 0px 6px;

      &.mod-visa {
        padding: 14px 4px;
      }
      &.mod-mastercard {
        padding: 2px 0px;
      }
      &.mod-american-express {
        padding: 4px 4px;
      }
      &.mod-jcb {
        padding: 4px;
      }
      &.mod-diners-club {
        padding: 6px;
      }

      &.mod-inactive {
        opacity: 0.3;
      }
    }
  }
}
