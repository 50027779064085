@import './styles/AppDesign.scss';

html,
body {
  height: 100%;
  width: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -webkit-touch-callout: none;
}

html {
  background-color: $bgGray;
  display: flex;
  align-items: center;
}

input,
textarea,
button {
  outline: none;
  -webkit-appearance: none;
}

body {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
    'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $TextColor;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

#root {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

a {
  color: $TextColor;
  text-decoration: none;
}

.application-version {
  font-size: 1rem;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
}

// @TODO: Consider move to some file
.common-form {
  &-field-amount {
    position: relative;

    input {
      padding-left: 70px !important;
    }

    &-prefix {
      position: absolute;
      left: 15px;
      top: 24px;
    }
  }
}
