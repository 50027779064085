@import '../../styles/AppDesign.scss';

.LangSwitchButton {
  background: transparent;
  border: none;
  box-sizing: border-box;
  height: 54px;
  display: flex;
  align-items: center;
  cursor: pointer;

  &-title {
    color: $TextGray;
    padding-right: 4px;
  }

  &-icon {
    display: block;
    width: 18px;
    height: 18px;
    background-color: $SubTextColor;
    mask: url('/assets/icons/globe.svg') no-repeat center;
    mask-size: contain;
  }
}
