@import '../../styles/AppDesign.scss';
@import '../../styles/media-queries.scss';

.VisitorLayout {
  &-img {
    display: none;
  }

  &-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    margin: 0 auto;
    padding: 16px;
    padding-bottom: 100px;
    box-sizing: border-box;
  }

  &-header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &-form {
    width: 100%;
    margin-top: 60px;

    .InputTextField {
      margin-bottom: 24px;
    }

    &-error {
      padding-left: 0.3rem;
      margin-top: 1.5rem;
      color: $ErrorRed;
      opacity: 0;

      &.mod-show {
        opacity: 1;
      }
    }

    &-remark {
      font-size: 12px;
      color: $SubTextColor;
    }

    &-buttons {
      width: 100%;
      display: flex;
      flex-direction: row;

      .cta-button {
        flex: 1;
        margin: 1.5rem 0.5rem 0;

        &:first-child {
          margin-left: 0px;
        }
        &:last-child {
          margin-right: 0px;
        }
      }
    }
  }

  &-hint-button {
    color: $MainCyan;
    padding: 0.5rem;
    margin-top: 3rem;
  }
}

@include desktop {
  .VisitorLayout {
    display: flex;
    height: 100%;
    position: relative;

    &-img {
      position: fixed;
      display: block;
      width: 50%;
      object-fit: cover;
      -webkit-mask-image: linear-gradient(-90deg, $black 55%, transparent);
      right: 0;
      height: 100%;
    }

    &-header {
      margin-bottom: auto;
    }

    &-hint-button {
      margin-bottom: auto;
    }

    &-container {
      width: 50%;
      padding-left: 2.5%;
      padding-right: 5%;
      position: absolute;
      left: 0;

      padding-top: 2%;
      padding-bottom: 2%;
    }
  }
}
