$black: #000000;
$white: #ffffff;
$bgGray: #f1f5f7;
$bgGrayTranslucent: #f1f5f7bb;
$MainLightCyan: #92e8ee;
$MainCyan: #06bbc9;
$SelectedCyan: #11a3b5;
$TextColor: #0d2238;
$TextDarkerGray: #777f88;
$TextGray: #9ba6b2;
$LightGray: #caced1;
$LighterGray: #ddd;
$SubTextColor: hsl(211, 13%, 65%);
$BorderGray: #f6f8fb;
$ErrorRed: #a50203;
$NavGray: #e6eaed;
$GenericBoxShadow: 0px 8px 24px rgba(149, 157, 165, 0.05);
$CoralRed: #fa6b6b;
$NeonGreen: #68d000;
$GoogleFontColor: #3d4144;
$DisabledInputField: #ebebeb;

$AppPadding: 16;
$AppPaddingPx: #{$AppPadding}px;
